(function() {
    'use strict';

    angular.module("adminApp")
        .factory('aflAdminUserDeleteModal', aflAdminUserDeleteModal);

    aflAdminUserDeleteModal.$inject = ['$uibModal'];

    function aflAdminUserDeleteModal($uibModal) {
        function open(user) {
            var modal = $uibModal.open({
                size: "md",
                templateUrl: "/static/javascript/directives/afl-admin-user-delete-modal/afl-admin-user-delete-modal.html",
                controller: aflAdminUserDeleteModalController,
                controllerAs: '$ctrl',
                resolve: {
                    user: function() {
                        return user;
                    }
                }
            });
            return modal.result;
        }

        return {
            open: open
        }
    }

    aflAdminUserDeleteModalController.$inject = ['$uibModalInstance', 'aerosAdminApi', 'Notification', 'user'];

    function aflAdminUserDeleteModalController($uibModalInstance, aerosAdminApi, Notification, user) {

        var $ctrl = this;

        angular.extend($ctrl, {
            deleteUser: deleteUser
        });

        $ctrl.fullName = user.firstName + " " + user.lastName;

        function deleteUser() {
            return aerosAdminApi.deleteAdminUser(user.email)
                .then(function (res) {
                    Notification.success("User " + user.email + " deleted");
                    $uibModalInstance.close();
                }, function (err) {
                    Notification.error("User " + user.email + " not deleted. " + err.data.message);
                });
        }
    }
})();
